import { BusinessVerifyIcon, CheckIcon } from 'modules/shared/Icons'
import { Box, Flex, Heading, Link, List, ListItem, Text } from '@chakra-ui/react'
import ButtonTheme from 'modules/shared/ButtonTheme'

const VerifyBusiness = ({handleConfirmed}:any) => {
    return (
        <Box m={"0 auto"}>
            <Box w={'100%'} p={3} textAlign={'center'}>
                <Flex alignItems={"center"} justifyContent={"center"} m={"0 auto"} borderRadius={"full"} w={40} h={40} bgColor={"Neutral.100"}>
                    <BusinessVerifyIcon w={24} h={24} />
                </Flex>
                <Heading as={'h4'} mt={4} mb={4} fontSize={'3xl'} color={'Primary.Navy'}>
                    Verify your business
                </Heading>
                <Text mb={10} color={'Neutral.800'}>
                    To meet legal requirements, we need to verify a few details about your business before you continue using HEALE.
                </Text>
                <Box mb={10} p={10} border={"1px solid"} borderColor={"Neutral.200"} borderRadius={"24"}>
                    <Heading fontSize={"xl"} mb={6} color={"Primary.Navy"}>What you will need:</Heading>
                    <List w={"80%"} m={"0 auto"} spacing={4} color={'Neutral.700'} fontSize={'lg'}>
                        <ListItem display={'flex'} alignItems={"center"} gap={3}>
                            <CheckIcon w={5} h={5} />
                            <Text as={'span'}>
                                Business address and incorporation details
                            </Text>
                        </ListItem>
                        <ListItem display={'flex'} alignItems={"center"} gap={3}>
                            <CheckIcon w={5} h={5} />
                            <Text as={'span'}>
                                Owner information
                            </Text>
                        </ListItem>
                    </List>
                </Box>
                <ButtonTheme
                    btnText={"Continue"}
                    onClick={handleConfirmed}
                    chakraProps={{
                        width: '60%',
                    }}
                    primary
                />
                <Link color="Primary.Blue" my={3} display={'block'}>
                    Close
                </Link>
            </Box>
        </Box>
    )
}

export default VerifyBusiness