import React, { useState, useEffect, useMemo, ChangeEvent } from 'react';
import {
  Box,
  Flex,
  Grid,
  Link,
  Heading,
  Progress,
  Text,
  useDisclosure,
  Stack,
  Checkbox,
} from '@chakra-ui/react';
import ButtonTheme from 'modules/shared/ButtonTheme';
import { AddProfileIcon, InfoIcon } from 'modules/shared/Icons';
import VerificationBox from '../VerificationBox';
import ApplicationCollabModal from '../ApplicationCollabModal';
import { useWizard } from 'react-use-wizard';
import BrokerModal from 'modules/onboarding/business/broker';
import CarrierModal from 'modules/onboarding/business/carrier';
import LenderModal from 'modules/onboarding/business/lender';
import { getQuestionaireToLocalStorage } from 'services/localStorage.sevice';
import { calculateQuestions } from 'utils/helpers';
import { useLocation } from 'react-router-dom';
import { getBusinessApplication } from 'services/user.service';
import { useBusiness } from 'context/BusinessContext';
import OwnerInfo from 'modules/onboarding/business/owner-info';

interface Questions {
  [key: string]: {
    filled: number;
    total: number;
  };
}

const Application = () => {
  const [currentScreen, setCurrentScreen] = useState('Application');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { selectedTypes } = useBusiness();
  const {
    isOpen: isBrokerOpen,
    onOpen: onBrokerOpen,
    onClose: onBrokerClose,
  } = useDisclosure();
  const {
    isOpen: isCarrierOpen,
    onOpen: onCarrierOpen,
    onClose: onCarrierClose,
  } = useDisclosure();
  const {
    isOpen: isLenderOpen,
    onOpen: onLenderOpen,
    onClose: onLenderClose,
  } = useDisclosure();
  const {
    isOpen: isOwnerOpen,
    onOpen: onOwnerOpen,
    onClose: onOwnerClose,
  } = useDisclosure();
  const [businessParam, setBusinessParam] = useState<string | null>(null);
  const { nextStep, previousStep } = useWizard();

  const location = useLocation();
  const [applicationId, setApplicationId] = useState(null);
  const [brokerData, setBrokerData] = useState(null);
  const [carrierData, setCarrierData] = useState(null);
  const [lenderData, setLenderData] = useState(null);
  const [questions, setQuestions] = useState<Questions>({
    broker: { filled: 0, total: 6 },
    carrier: { filled: 0, total: 5 },
    lender: { filled: 0, total: 1 },
  });

  // new logic for checkboxes
  const [isAnyCheckboxSelected, setIsAnyCheckboxSelected] = useState(false);
  const [showOtherBox, setShowOtherBox] = useState(false);

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    const anyChecked = Array.from(checkboxes).some(
      (checkbox) => (checkbox as HTMLInputElement).checked
    );

    setIsAnyCheckboxSelected(anyChecked);
  };

  const handleContinue = () => {
    setShowOtherBox(true);
  };
  // new logic for checkboxes

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const business = params.get('business');
    setBusinessParam(business);
    if (business) {
      getBusinessApplication(business)
        .then((data) => {
          if (data) {
            setApplicationId(data.data.application.id);
            const brokerDataObj = data.data.brokerData
              ? {
                ...data.data.brokerData,
                services: JSON.parse(data.data.brokerData.services || '[]'),
                insurance: JSON.parse(data.data.brokerData.insurance || '[]'),
                surety_bond: JSON.parse(
                  data.data.brokerData.surety_bond || '[]'
                ),
              }
              : null;

            const carrierDataObj = data.data.carrierData
              ? {
                ...data.data.carrierData,
                services: JSON.parse(data.data.carrierData.services || '[]'),
                insurance: JSON.parse(
                  data.data.carrierData.insurance || '[]'
                ),
              }
              : null;

            const lenderDataObj = data.data.lenderData
              ? { ...data.data.lenderData }
              : null;

            setBrokerData(brokerDataObj);
            setCarrierData(carrierDataObj);
            setLenderData(lenderDataObj);
            setQuestions({
              broker: {
                filled: calculateQuestions(brokerDataObj)?.filledQuestions ?? 0,
                total: calculateQuestions(brokerDataObj)?.totalQuestions ?? 6,
              },
              carrier: {
                filled:
                  calculateQuestions(carrierDataObj)?.filledQuestions ?? 0,
                total: calculateQuestions(carrierDataObj)?.totalQuestions ?? 5,
              },
              lender: {
                filled: calculateQuestions(lenderDataObj)?.filledQuestions ?? 0,
                total: calculateQuestions(lenderDataObj)?.totalQuestions ?? 1,
              },
            });
          }
        })
        .catch((error) => console.error(error));
    } else {
      let savedValues = getQuestionaireToLocalStorage() ?? {};
      setQuestions({
        broker: {
          filled:
            calculateQuestions(savedValues['broker'])?.filledQuestions ?? 0,
          total: calculateQuestions(savedValues['broker'])?.totalQuestions ?? 6,
        },
        carrier: {
          filled:
            calculateQuestions(savedValues['carrier'])?.filledQuestions ?? 0,
          total:
            calculateQuestions(savedValues['carrier'])?.totalQuestions ?? 5,
        },
        lender: {
          filled:
            calculateQuestions(savedValues['lender'])?.filledQuestions ?? 0,
          total: calculateQuestions(savedValues['lender'])?.totalQuestions ?? 1,
        },
      });
    }
  }, [location.search]);

  const progress = useMemo(() => {
    let totalFilled = 0;
    let totalQuestions = 0;

    const updateProgress = (type: keyof Questions) => {
      totalFilled += questions[type].filled;
      totalQuestions += questions[type].total;
    };

    if (selectedTypes.length > 0) {
      selectedTypes.forEach((type) => {
        if (type === 'Freight Broker' && questions.broker) {
          updateProgress('broker');
        } else if (type === 'Carrier' && questions.carrier) {
          updateProgress('carrier');
        } else if (type === 'Lender' && questions.lender) {
          updateProgress('lender');
        }
      });
    } else {
      // Default to including all if no types are explicitly selected
      updateProgress('broker');
      updateProgress('carrier');
      updateProgress('lender');
    }

    return totalQuestions > 0 ? totalFilled / totalQuestions : 0;
  }, [selectedTypes, questions]);

  // Function to handle navigation to a specific screen
  const handleNavigation = (screen: any) => {
    setCurrentScreen(screen);
  };

  // Function to go back to the Application screen
  const handleBackToApplication = () => {
    setCurrentScreen('Application');
  };

  return (
    <Box w={{ "2xl": '60%', lg: '70%', md: "80%", base: '100%' }} m={'0 auto'}>
      {!showOtherBox && (
        <>
          <Box textAlign={'center'}>
            <Heading fontSize={"3xl"} mb={10} color={"Primary.Navy"}>
              Is your company any of the following?
            </Heading>
          </Box>
          <Stack spacing={4} sx={{ ".chakra-checkbox__label": { fontSize: "sm" } }}>
            <Checkbox onChange={handleCheckboxChange}>
              Registered with the SEC
            </Checkbox>
            <Checkbox onChange={handleCheckboxChange}>
              A publicly-traded company
            </Checkbox>
            <Checkbox onChange={handleCheckboxChange}>
              Majority owned by a public company
            </Checkbox>
            <Checkbox onChange={handleCheckboxChange}>
              An internet gambling business
            </Checkbox>
            <Checkbox onChange={handleCheckboxChange}>
              Dealing with Controlled Substances (THC, other Schedule I drugs)
            </Checkbox>
            <Checkbox onChange={handleCheckboxChange}>
              Involved in the sale, distribution or manufacturing of firearms or ammunition
            </Checkbox>
            <Checkbox onChange={handleCheckboxChange}>
              A government organization
            </Checkbox>
            <Checkbox onChange={handleCheckboxChange}>
              Part of a tax anticipation program
            </Checkbox>
            <Checkbox onChange={handleCheckboxChange}>
              An adult entertainment business
            </Checkbox>
          </Stack>
          <Flex flexDir={'column'} alignItems={'center'} mt={8} gap={6}>

            <ButtonTheme
              primary
              btnText={isAnyCheckboxSelected ? 'Continue' : 'None of the above'}
              chakraProps={{
                w: "35%",
                onClick: handleContinue

              }}
            />
            <Link color="Primary.Blue" onClick={() => previousStep()}>
              Back
            </Link>
          </Flex>
        </>
      )
      }
      {
        showOtherBox && (
          <>
            {currentScreen === 'Application' && (
              <>
                <Box textAlign={'center'}>
                  <Heading as={'h4'} mb={4} fontSize={'3xl'} color={'Primary.Navy'}>
                    Verify your identify
                  </Heading>
                  <Text mb={8} color={'Neutral.800'}>
                    We have a few questions to verify your identity for compliance
                    purposes. Your information is transmitted and stored securely.
                  </Text>
                </Box>
                <Flex gap={2} alignItems={'center'}>
                  <Progress
                    w={{ sm: '80%', base: '60%' }}
                    size="sm"
                    borderRadius={'full'}
                    value={progress * 100}
                  />
                  <Text color={'Neutral.700'} fontSize={'sm'}>
                    {(progress * 100).toFixed(0)}% complete
                  </Text>
                </Flex>
                {!businessParam && (
                  <Flex
                    flexDir={{ md: 'row', base: 'column' }}
                    my={10}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Heading mb={2} fontSize={'md'} color={'Primary.Navy'}>
                      Invite a team member to collaborate
                    </Heading>
                    <Flex
                      alignItems={'center'}
                      color={'Primary.Blue'}
                      onClick={onOpen}
                      cursor={'pointer'}
                      gap={2}
                    >
                      <AddProfileIcon
                        sx={{
                          path: {
                            stroke: 'Primary.Blue',
                          },
                        }}
                      />
                      <Text>Invite Collaborators</Text>
                    </Flex>
                  </Flex>
                )}

                <Grid
                  gridTemplateColumns={{
                    sm: 'repeat(2,1fr)',
                    base: 'repeat(1,1fr)',
                  }}
                  gap={6}
                >
                  {selectedTypes.includes('Freight Broker') && (
                    <VerificationBox
                      onClick={() => handleNavigation('Broker')}
                      questions={questions} // Use actual data or state
                      title="Broker"
                    />
                  )}
                  {selectedTypes.includes('Carrier') && (
                    <VerificationBox
                      onClick={() => handleNavigation('Carrier')}
                      questions={questions} // Use actual data or state
                      title="Carrier"
                    />
                  )}
                  {selectedTypes.includes('Lender') && (
                    <VerificationBox
                      onClick={() => handleNavigation('Lender')}
                      questions={questions} // Use actual data or state
                      title="Lender"
                    />
                  )}
                  <VerificationBox
                    onClick={() => handleNavigation('Owner')}
                    questions={questions} // Use actual data or state
                    title="Owner"
                  />
                </Grid>

                {!businessParam && (
                  <ApplicationCollabModal isOpen={isOpen} onClose={onClose} />
                )}

                {!businessParam && (
                  <Flex flexDir={'column'} alignItems={'center'} mt={8} gap={6}>
                    <ButtonTheme
                      type="submit"
                      btnText="Continue"
                      // isDisabled={!isDirty || !isValid}
                      chakraProps={{
                        w: '35%',
                      }}
                      primary
                    />
                    <Link color="Primary.Blue" onClick={() => previousStep()}>
                      Back
                    </Link>
                  </Flex>
                )}
              </>
            )}
            {currentScreen === 'Broker' && (
              <BrokerModal
                onClose={handleBackToApplication}
                setQuestions={setQuestions}
                brokerData={brokerData}
                applicationId={applicationId}
              />
            )}
            {currentScreen === 'Carrier' && (
              <CarrierModal
                onClose={handleBackToApplication}
                setQuestions={setQuestions}
                carrierData={carrierData}
                applicationId={applicationId}
              />
            )}
            {currentScreen === 'Lender' && (
              <LenderModal
                onClose={handleBackToApplication}
                setQuestions={setQuestions}
                lenderData={lenderData}
                applicationId={applicationId}
              />
            )}
            {currentScreen === 'Owner' && (
              <OwnerInfo onClose={handleBackToApplication} />
            )}
          </>
        )
      }
    </Box >
  );
};

export default Application;
