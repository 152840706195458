import { Box, Flex, Heading, Icon, Link, Text, keyframes } from '@chakra-ui/react';
import ButtonTheme from 'modules/shared/ButtonTheme';
import { LoaderIcon } from 'modules/shared/Icons';
import React from 'react';
import { MessageBoxType } from 'type';

const spin = keyframes`
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg)}
`;

const MessageBox = ({
  icon,
  title,
  desc,
  btnText,
  trust,
  loader,
  handleClick,
}: MessageBoxType) => {
  const spinAnimation = `${spin} infinite 3s linear`;
  return (
    <Box w={'100%'} p={3} textAlign={'center'}>
      <Flex alignItems={"center"} justifyContent={"center"} m={"0 auto"} borderRadius={"full"} w={40} h={40} bgColor={"Neutral.100"}>
        <Icon as={icon} w={24} h={24} />
      </Flex>
      <Heading as={'h4'} mt={4} mb={4} fontSize={'3xl'} color={'Primary.Navy'}>
        {title}
      </Heading>
      <Text mb={btnText || loader ? 8 : 0} color={'Neutral.800'}>
        {desc}
      </Text>
      {(btnText) && (
        <ButtonTheme
          // isLoading={loader}
          // isDisabled={loader}
          btnText={btnText}
          chakraProps={{
            onClick: () => handleClick && handleClick(),
            isDisabled: loader,
            // onClick: () => nextStep(),
            width: '60%',
          }}
          primary
        />
      )}
      {trust && (
        <Link color="Primary.Blue" my={3} display={'block'}>
          Don't Trust
        </Link>
      )}
      {/* {loader && <LoaderIcon animation={spinAnimation} w={8} h={8} />} */}
    </Box>
  );
};

export default MessageBox;
