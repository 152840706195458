// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../src/assets/fonts/Aeonik-Regular.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: "Aeonik Regular";
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
}

.courier-prime-regular {
    font-family: "Courier Prime", monospace;
    font-weight: 400;
    font-style: normal;
}

input,
select,
.rmsc .dropdown-container,
.rmsc .dropdown-heading {
    height: 2.75rem !important;
    font-size: 14px !important;
}

label {
    font-size: 14px !important;
    font-weight: 600 !important;
    text-transform: capitalize;
}

.rmsc.multi-select {
    width: 352px;
}

.rmsc.custom-multi-select {
    width: 590px;
}

.rmsc.custom-multi-select2 {
    width: 269px;
}

.rmsc .search input {
    margin: 12px 16px;
    width: -webkit-fill-available !important;
    border-radius: 40px;
    background-color: rgba(241, 241, 255, 1) !important
}

.rmsc .item-renderer input {
    height: auto !important;
}

.rmsc .options li {
    border-top: 1px solid rgba(241, 241, 255, 1);
}

.rmsc .item-renderer {
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;IAClB,+DAAqE;AACzE;;AAEA;IACI,uCAAuC;IACvC,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;;;;IAII,0BAA0B;IAC1B,0BAA0B;AAC9B;;AAEA;IACI,0BAA0B;IAC1B,2BAA2B;IAC3B,0BAA0B;AAC9B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,wCAAwC;IACxC,mBAAmB;IACnB;AACJ;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,4CAA4C;AAChD;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":["@font-face {\n    font-family: \"Aeonik Regular\";\n    font-style: normal;\n    font-weight: normal;\n    font-display: swap;\n    src: url(\"../src/assets/fonts/Aeonik-Regular.otf\") format(\"truetype\");\n}\n\n.courier-prime-regular {\n    font-family: \"Courier Prime\", monospace;\n    font-weight: 400;\n    font-style: normal;\n}\n\ninput,\nselect,\n.rmsc .dropdown-container,\n.rmsc .dropdown-heading {\n    height: 2.75rem !important;\n    font-size: 14px !important;\n}\n\nlabel {\n    font-size: 14px !important;\n    font-weight: 600 !important;\n    text-transform: capitalize;\n}\n\n.rmsc.multi-select {\n    width: 352px;\n}\n\n.rmsc.custom-multi-select {\n    width: 590px;\n}\n\n.rmsc.custom-multi-select2 {\n    width: 269px;\n}\n\n.rmsc .search input {\n    margin: 12px 16px;\n    width: -webkit-fill-available !important;\n    border-radius: 40px;\n    background-color: rgba(241, 241, 255, 1) !important\n}\n\n.rmsc .item-renderer input {\n    height: auto !important;\n}\n\n.rmsc .options li {\n    border-top: 1px solid rgba(241, 241, 255, 1);\n}\n\n.rmsc .item-renderer {\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
