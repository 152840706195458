import { Box, Flex, Heading, Image, List, ListItem, Text } from "@chakra-ui/react"
import ButtonTheme from "modules/shared/ButtonTheme"
import { CheckIcon, VerificationFailedIcon } from "modules/shared/Icons"
import { useEffect, useState } from "react";

const VerifyingBusinessTransition = ({type, handleNext}:any) => {
    const [verified, setVerified] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setVerified(true);
        }, 3000);
        return () => clearTimeout(timer);
    }, []);
    return (
        <Box m={"0 auto"}>
            <Box w={'100%'} p={3} textAlign={'center'}>
                {verified ?
                    <Flex alignItems={"center"} justifyContent={"center"} m={"0 auto"} borderRadius={"full"} w={40} h={40} bgColor={"Neutral.100"}>
                        <VerificationFailedIcon w={24} h={24} />
                    </Flex>
                    :
                    <Image src="/HealeFavIcon.png" w={40} m={"0 auto"} />}
                <Heading as={'h4'} mt={4} mb={4} fontSize={'3xl'} color={'Primary.Navy'}>
                    {verified ? `Unable to verify your ${type}` : `Verifying your ${type === 'business' ? 'business' : 'info'}`}
                </Heading>
                <Text mb={10} color={'Neutral.800'}>
                    {verified ? `Unfortunately we were unable to verify your ${type === 'business' ? 'business' : 'identity with our compliance partner'}. This can happen due to` : "We're verifying the business information you entered."}

                </Text>
                {verified &&
                    <Box mb={10}>
                        <Heading fontSize={"xl"} mb={6} color={"Primary.Navy"}>What you will need:</Heading>
                        <List w={"80%"} m={"0 auto"} mb={10} spacing={4} color={'Neutral.700'} fontSize={'lg'}>
                            <ListItem display={'flex'} alignItems={"center"} gap={3}>
                                <CheckIcon w={5} h={5} />
                                <Text as={'span'}>
                                    A typo in entry
                                </Text>
                            </ListItem>
                            <ListItem display={'flex'} alignItems={"center"} gap={3}>
                                <CheckIcon w={5} h={5} />
                                <Text as={'span'}>
                                    An error with our verification
                                </Text>
                            </ListItem>
                            <ListItem display={'flex'} alignItems={"center"} gap={3}>
                                <CheckIcon w={5} h={5} />
                                <Text as={'span'}>
                                    A mismatch of information from your application
                                </Text>
                            </ListItem>
                        </List>
                        <ButtonTheme
                            onClick={handleNext}
                            btnText={"Continue"}
                            chakraProps={{
                                width: '60%',
                            }}
                            primary
                        />
                    </Box>}
            </Box>
        </Box>
    )
}

export default VerifyingBusinessTransition